<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="데이터를 업로드 하는 중입니다."
  >
    <section class="contWrap alarm table">
      <article class="top">
        <div class="card">
          <h3>이상감지 알림 조회</h3>
          <div class="flex">
            <div class="block">
              <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="시작 날짜"
              >
              </el-date-picker>
              <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="마감 날짜"
              >
              </el-date-picker>
            </div>
            <div class="searchBox">
              <input type="text" class="searchInput" v-model="searchWord" />
              <button class="basic">
                <span class="material-icons" @click="refresh"> refresh </span>
              </button>
              <button class="point" @click="submit">
                <span class="material-icons"> search </span>
              </button>
            </div>
          </div>
        </div>
      </article>
      <article class="bottom card">
        <div class="tableBox">
          <table>
            <colgroup>
              <col style="width: 15%" />
              <col style="width: 25%" />
              <col style="width: 34%" />
              <col style="width: 13%" />
              <col style="width: 13%" />
            </colgroup>
            <tr>
              <th>
                <div class="flex">
                  설비 ID
                  <div class="sorting">
                    <button class="top" :class="{ active: tableSort[0] == true }" @click="handleSort(0)"></button>
                    <button class="bottom" :class="{ active: tableSort[1] == true }" @click="handleSort(1)"></button>
                  </div>
                </div>
              </th>
              <th>
                <div class="flex">
                  Date/Time
                  <div class="sorting">
                    <button class="top" :class="{ active: tableSort[2] == true }" @click="handleSort(2)"></button>
                    <button class="bottom" :class="{ active: tableSort[3] == true }" @click="handleSort(3)"></button>
                  </div>
                </div>
              </th>
              <th>
                <div class="flex">
                  푸시 알림 내역
                  <div class="sorting">
                    <button class="top" :class="{ active: tableSort[4] == true }" @click="handleSort(4)"></button>
                    <button class="bottom" :class="{ active: tableSort[5] == true }" @click="handleSort(5)"></button>
                  </div>
                </div>
              </th>
              <th>
                <div class="flex">
                  잔여 수명
                  <div class="sorting">
                    <button class="top" :class="{ active: tableSort[6] == true }" @click="handleSort(6)"></button>
                    <button class="bottom" :class="{ active: tableSort[7] == true }" @click="handleSort(7)"></button>
                  </div>
                </div>
              </th>
              <th>
                <div class="flex">
                  전조 예측
                  <div class="sorting">
                    <button class="top" :class="{ active: tableSort[8] == true }" @click="handleSort(8)"></button>
                    <button class="bottom" :class="{ active: tableSort[9] == true }" @click="handleSort(9)"></button>
                  </div>
                </div>
              </th>
            </tr>
            <tr v-for="(data, i) in alaramList" :key="i">
              <td>{{data.equipmentId}}</td>
              <td>{{ moment(data.created_at).format("YYYY.MM.DD HH:mm")}}</td>
              <td>
                anomaly_score: {{data.anomaly_score}}
                <br>
                anomaly: {{data.anomaly}}
              </td>
              <td>
                <div class="progress">
                  <div class="progressBar" :style="{width: `${data.rul}%`}">
                    <span>{{data.rul}}%</span>
                  </div>
                </div>
              </td>
              <td>{{data.forecast}}</td>
            </tr>
          </table>
        </div>
        <div class="pagination">
          <el-pagination
            v-if="total > 10"
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { fetchSensorAlarm } from "@/api/index";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      loading: false,
      moment: moment,
      startDate: "",
      endDate: "",
      total: 0,
      page: 1,
      searchWord: "",
      alaramList: [],
      value: localStorage.getItem("equipId"),
      tableData: [
        {
          tag: "컴프레셔",
          date: "2022.01.14 14:02:04",
          alarm: "Tom",
          battery: "50%",
          prediction: "50",
        },
        {
          tag: "진공펌프",
          date: "2022.01.14 14:02:04",
          alarm: "Tom",
          battery: "50%",
          prediction: "50",
        },
        {
          tag: "모터",
          date: "2022.01.14 14:02:04",
          alarm: "Tom",
          battery: "50%",
          prediction: "50",
        },
        {
          tag: "진공펌프",
          date: "2022.01.14 14:02:04",
          alarm: "Tom",
          battery: "50%",
          prediction: "50",
        },
      ],
      tableSort: [true,false,false,false,false,false,false,false,false,false],
    };
  },
  
  computed: {
    ...mapState(["userId", "_id", "equipment"]),
  },
  created() {
    this.$store.dispatch("SET_NAV_INDEX", "5");
  },
  mounted() {
    this.getSensorAlarm();
  },
  methods: {
    /**
     * 테이블 정보 컬럼별 정렬 기능
     */
    handleSort(val) {
      this.tableSort = [false,false,false,false,false,false,false,false,false,false];
      this.tableSort[val] = true;
      switch(val) {
        //설비 ID
        case 0:
          this.alaramList.sort((a, b) => {
            if(a.equipmentId > b.equipmentId) return 1;
            if(a.equipmentId < b.equipmentId) return -1;
            return 0;
          });
          break;
        case 1:
          this.alaramList.sort((a, b) => {
            if(b.equipmentId > a.equipmentId) return 1;
            if(b.equipmentId < a.equipmentId) return -1;
            return 0;
          });
          break;
        // time
        case 2:
          this.alaramList.sort((a, b) => {
            if(a.created_at > b.created_at) return 1;
            if(a.created_at < b.created_at) return -1;
            return 0;
          });
          break;
        case 3:
          this.alaramList.sort((a, b) => {
            if(b.created_at > a.created_at) return 1;
            if(b.created_at < a.created_at) return -1;
            return 0;
          });
          break;
        // 푸시 알람 내역
        case 4:
                    this.alaramList.sort((a, b) => {
            if(a.emailList > b.emailList) return 1;
            if(a.emailList < b.emailList) return -1;
            return 0;
          });
          break;
        case 5:
          this.alaramList.sort((a, b) => {
            if(b.emailList > a.emailList) return 1;
            if(b.emailList < a.emailList) return -1;
            return 0;
          });
          break;
        // 잔여수명
        case 6:
                    this.alaramList.sort((a, b) => {
            if(a.rul > b.rul) return 1;
            if(a.rul < b.rul) return -1;
            return 0;
          });
          break;
        case 7:
          this.alaramList.sort((a, b) => {
            if(b.rul > a.rul) return 1;
            if(b.rul < a.rul) return -1;
            return 0;
          });
          break;
        // 전조예측
        case 8:
                    this.alaramList.sort((a, b) => {
            if(a.forecast > b.forecast) return 1;
            if(a.forecast < b.forecast) return -1;
            return 0;
          });
          break;
        case 9:
          this.alaramList.sort((a, b) => {
            if(b.forecast > a.forecast) return 1;
            if(b.forecast < a.forecast) return -1;
            return 0;
          });
          break;
        default:
          this.getSensorAlarm();
          break;
      }
    },
    /**
     * 알람 정보 검색 기능
     */
    submit() {
      this.getSensorAlarm();
    },
    /**
     * 필요 페이지 정보 리셋 기능
     */
    refresh() {
      this.startDate = "";
      this.endDate = "";
      this.total = 0;
      this.page = 1;
      this.searchWord = "";
      this.getSensorAlarm();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getSensorAlarm();
    },
    /**
     * 알람 정보 가져오기 기능
     */
    getSensorAlarm() {
      let params = {
        page: this.page,
        startDate: this.startDate,
        endDate: this.endDate,
        searchWord: this.searchWord,
      };
      fetchSensorAlarm(params).then((res) => {
        if (res.data.status != 200) {
          console.log("fetchSensorAlarm-1", res.data.message);
        } else {
          this.total = res.data.total;
          this.alaramList = res.data.data;
        }
      });
    },
  },
};
</script>
